import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules"; // Import EffectFade for fade transitions
import "swiper/css"; // Swiper CSS
import "swiper/css/effect-fade"; // Swiper fade effect CSS
import "./Slideshow.css";

const images = [
  require("../assets/slideshow/image1.jpg"),
  require("../assets/slideshow/image2.jpg"),
  require("../assets/slideshow/image3.jpg"),
  require("../assets/slideshow/image4.jpg"),
  require("../assets/slideshow/image5.jpg"),
];

const Slideshow = () => {
  return (
    <div className="slideshow">
      <Swiper
        modules={[Autoplay, EffectFade]} // Add EffectFade module here
        autoplay={{ delay: 6000, disableOnInteraction: false }}
        effect="fade" // Set fade effect
        fadeEffect={{ crossFade: true }} // Enable crossfade for smoother transitions
        loop={true}
        speed={1000} // Set transition speed to 2000ms (2 seconds) for smoother effect
        className="swiper-container"
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <div
              className="slideshow-image"
              style={{ backgroundImage: `url(${image})` }}
            >
              {/* Darkened mask */}
              <div className="darkened-mask"></div>

              <div className="slideshow-content">
                <div className="welcome-text">
                  <p className="main-line">Welcome to Desert Canyon Inn</p>
                  <div className="dci-line-container">
                    <div className="line"></div>
                    <span className="welcome-line">DCI</span>
                    <div className="line"></div>
                  </div>
                  <p className="welcome-line">
                    Located in the heart of Downtown Page, Arizona
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slideshow;
